<template>
  <v-dialog
    v-model="dialog"
    max-width="600px"
    :fullscreen="$vuetify.breakpoint.mobile"
    persistent
  >
    <v-card>
      <v-card-title primary-title>
        Contagem de produto
        <v-btn
          small
          outlined
          class="text-capitalize ml-auto"
          @click="automatic_counting = !automatic_counting"
          :color="automatic_counting ? 'success' : 'error'"
        >
          <v-icon class="mr-3">mdi-power</v-icon>
          Cont auto.</v-btn
        >
      </v-card-title>
      <v-card-text>
        <v-row class="mt-1">
          <v-col>
            <ProductSearchCode
              v-model="form.product"
              @input="handleAddProduct()"
            />
          </v-col>
        </v-row>

        <template v-if="isAutoCounting">
          <v-row v-if="alert">
            <v-col>
              <v-alert dense type="success">
                {{ stockAuditProduct.product_company.product.name }}-
                {{ stockAuditProduct.product_company.product.code }} <br />
                adicionado com sucesso!
              </v-alert>
            </v-col>
          </v-row>
        </template>
        <v-row>
          <v-col v-if="!isAutoCounting">
            <ProductSearch v-model="form.product" />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="6" md="4">
            <app-number-field
              :allowNegative="true"
              v-model="stockAuditProduct.stock_at_start"
              readonly
              disabled
              label="Estoque Inicial"
            />
          </v-col>
          <v-col cols="6" md="4">
            <app-number-field
              :allowNegative="true"
              v-model="stockAuditProduct.count"
              readonly
              disabled
              label="Contagem Atual"
            />
          </v-col>
          <v-col v-if="!isAutoCounting">
            <app-number-field
              :allowNegative="true"
              v-model="form.quantity"
              label="Estoque Atual"
            />
          </v-col>
        </v-row>
        <v-row v-if="!isAutoCounting">
          <v-col cols="3">
            <v-btn @click="valueNegative()" block color="error">
              Negativo
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="dialog = false" text color="secondary">Cancelar</v-btn>
        <v-btn @click="store()" color="primary">Salvar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import ProductSearch from "@/components/product/ui/ProductSearch.vue";
import ProductSearchCode from "@/components/product/ui/ProductSearchCode.vue";
export default {
  components: {
    ProductSearch,
    ProductSearchCode,
  },

  data() {
    return {
      dialog: false,
      alert: false,
      automatic_counting: false,

      stockAuditProduct: {
        product_company: { product: {} },
      },

      stock_audit: null,
      baseForm: {
        stock_audit_id: null,
        product_id: null,
        product_variant_id: null,
        product_company_id: null,
        stock_at_start: null,
        product: {},
        count: null,
        status: null,
      },
      form: {},
    };
  },

  watch: {
    alert: function (val) {
      if (val) {
        setTimeout(() => {
          this.alert = false;
        }, 60 * 1000);
      }
    },
  },

  computed: {
    isAutoCounting() {
      return this.automatic_counting;
    },
    product() {
      return this.form.product || null;
    },
    productVariant() {
      return this.product.product_variants[0] || null;
    },
    productCompany() {
      return this.productVariant.product_companies.find(
        (row) => row.company_id == this.stock_audit.company_id
      );
    },
  },

  created() {
    this.reset();
  },

  methods: {
    async open(stockAudit) {
      this.reset();

      this.stock_audit = stockAudit;

      this.dialog = true;
    },

    reset() {
      this.form = JSON.parse(JSON.stringify(this.baseForm));
    },

    async selectStockAuditProduct(product) {
      if (this.product != null) {
        this.$loading.start();
        var searchParams = {
          stock_audit_id: this.stock_audit.id,
          product_id: this.product.id,
          product_variant_id: this.productVariant.id,
          product_company_id: this.productCompany.id,
          status: "counting",
        };
        await this.$http
          .index("stock/stock-audit-product", searchParams)
          .then((response) => {
            if (response.stock_audit_products.total == 1) {
              this.stockAuditProduct = response.stock_audit_products.data[0];
            }

            this.alert = true;
            this.$loading.finish();
          })
          .catch((error) => {
            this.$loading.finish();
          });
      }
    },

    handleAddProduct() {
      if (this.isAutoCounting) {
        this.increaseProduct();
      }
    },

    async increaseProduct() {
      this.form.quantity = 1;
      await this.store();
      this.showAlert();
    },

    async store() {
      this.form.stock_audit = this.stock_audit;
      this.$loading.start();

      await this.$http
        .store("stock/stock-audit-product", this.form)
        .then((response) => {
          this.$emit("store");
          this.$loading.finish();
          this.selectStockAuditProduct();
          this.reset();
        })
        .catch((error) => {
          this.$loading.finish();
        });
    },

    valueNegative() {
      this.form.quantity = this.form.quantity * -1;
    },

    showAlert() {
      let context = new AudioContext(),
        oscillator = context.createOscillator();

      oscillator.type = "triangle";
      oscillator.connect(context.destination);
      oscillator.start();
      oscillator.stop(context.currentTime + 0.1);
      this.$snackbar({
        message: "Produto adicionado com sucesso! ",
        props: {
          color: "success",
          timeout: 3000,
        },
      });
    },
  },
};
</script>

<style lang="sass"  >
// colocar v-card-actions   acima do teclado virtual do celular para ficar sempre visivel como se fosse um app nativo
</style>
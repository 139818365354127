<template>
  <div>
    <v-row>
      <v-col class="d-flex align-center">
        <h4>{{ stockAudit.company.short_name }} | {{ stockAudit.name }} |</h4>
        <small class="ml-2">
          Iniciado em {{ $format.dateTimeBr(stockAudit.started_at) }}
        </small>
      </v-col>
      <v-col class="d-flex justify-end align-center">
        <v-btn
          outlined
          color="primary"
          class="mr-md-4 order-3 order-md-0 ma-md-0 my-2"
          v-if="$acl.can('updateStockViaAudit')"
          :disabled="isClosed"
          @click="handleUpdateStockFromStockAudit()"
        >
          <v-icon left>mdi-autorenew</v-icon> Atualizar Tudo
        </v-btn>
        <v-btn
          outlined
          color="error"
          v-if="$acl.can('updateStockViaAudit')"
          :disabled="isClosed"
          @click="handleCancelCounting()"
        >
          <v-icon left>mdi-close</v-icon>
          Cancelar Contagem
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="4" cols="12">
        <app-text-search-field @input="handleSearchInput($event)" />
      </v-col>
      <v-col
        class="d-flex justify-md-end flex-column flex-md-row"
        cols="12"
        md="8"
      >
        <v-btn
          outlined
          color="primary"
          class="mr-md-4 order-3 order-md-0 ma-md-0 my-2"
          v-if="$acl.can('updateStockViaAudit')"
          :disabled="isClosed"
          @click="handleStartCounting()"
        >
          <v-icon left>mdi-play</v-icon> Iniciar Contagem
        </v-btn>

        <v-btn
          color="primary"
          :disabled="isClosed"
          class="order-1 order-md-2 ma-md-0 my-2"
          v-if="$acl.can('stockAuditAddProduct')"
          @click="openStoreStockAuditProductForm()"
        >
          <v-icon left>mdi-plus</v-icon> Adicionar Produto
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="stock_audit_products.data"
          :items-per-page="-1"
          hide-default-footer
          disable-sort
          dense
        >
          <template v-slot:[`item.count`]="{ item }">
            {{ $format.decimal(item.count) }}
          </template>
          <template v-slot:[`item.stock_at_start`]="{ item }">
            {{ $format.decimal(item.stock_at_start) }}
          </template>
          <template v-slot:[`item.difference`]="{ item }">
            {{ $format.decimal(item.count - item.stock_at_start) }}
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <v-chip :color="statusData[item.status].color" small>
              {{ statusData[item.status].text }}
            </v-chip>
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <div class="d-flex" v-if="item.status != 'cancelled'">
              <v-btn
                text
                icon
                color="primary"
                :disabled="item.status != 'updated'"
                @click.stop="undoUpdateStock(item)"
                v-if="$acl.can('updateStockViaAudit')"
              >
                <v-icon>mdi-undo-variant</v-icon>
              </v-btn>
              <v-btn
                text
                icon
                color="primary"
                :disabled="item.status == 'updated'"
                @click.stop="handleUpdateStockFromStockAuditProduct(item.id)"
                v-if="$acl.can('updateStockViaAudit')"
              >
                <v-icon>mdi-autorenew</v-icon>
              </v-btn>
              <v-btn
                text
                icon
                color="error"
                :disabled="item.status == 'updated'"
                @click.stop="deleteProduct(item.id)"
                v-if="$acl.can('stockAuditCreateEdit')"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </div>
          </template>
        </v-data-table>

        <app-pagination
          :data="stock_audit_products"
          @click="pagination($event)"
        />
      </v-col>
    </v-row>
    <StoreStockAuditProductForm
      @store="selectStockAuditProduct()"
      ref="StoreStockAuditProductForm"
    />

    <StartStockAuditByBrandForm
      ref="StartStockAuditByBrandForm"
      @store="selectStockAuditProduct(searchParams.page)"
    />
    <CancelStockAuditByBrandForm
      ref="CancelStockAuditByBrandForm"
      @store="selectStockAuditProduct(searchParams.page)"
    />
  </div>
</template>

<script>
import StoreStockAuditProductForm from "@/components/stock-audit/forms/StoreStockAuditProductForm.vue";
import StartStockAuditByBrandForm from "@/components/stock-audit/forms/StartStockAuditByBrandForm.vue";
import CancelStockAuditByBrandForm from "@/components/stock-audit/forms/CancelStockAuditByBrandForm.vue";

export default {
  components: {
    StoreStockAuditProductForm,
    StartStockAuditByBrandForm,
    CancelStockAuditByBrandForm,
  },

  data() {
    return {
      stockAudit: {
        company: {},
      },

      statusData: {
        updated: {
          text: "Atualizado",
          color: "primary",
        },
        counting: {
          text: "Contando",
          color: "secondary",
        },
        cancelled: {
          text: "Cancelado",
          color: "error",
        },
      },
      stock_audit_products: {},
      searchParams: {
        stock_audit_id: null,
        page: null,
        status: null,
        text: null,
      },
      headers: [
        { text: "Código", value: "product_company.product.code" },
        { text: "Nome", value: "product_company.product.name", width: "50%" },
        { text: "Contagem", value: "count", align: "center" },
        {
          text: "Estoque inicial",
          value: "stock_at_start",
          align: "center",
          width: "15%",
        },
        { text: "Diferença", value: "difference", align: "center" },
        { text: "Status", value: "status", align: "center" },
        { text: "", value: "action", align: "end" },
      ],
    };
  },

  created() {
    this.selectStockAudit();
    this.selectStockAuditProduct();
  },
  computed: {
    isClosed() {
      return this.stockAudit.status == "closed";
    },
  },

  methods: {
    async selectStockAudit() {
      await this.$http
        .show("stock/stock-audit", this.$route.params.id)
        .then((response) => {
          this.stockAudit = response.stock_audit;
          this.$loading.finish();
        })
        .catch((error) => {
          this.$loading.finish();
        });
    },

    async selectStockAuditProduct() {
      this.$loading.start();
      this.searchParams.stock_audit_id = this.$route.params.id;

      await this.$http
        .index("stock/stock-audit-product", this.searchParams)
        .then((response) => {
          this.stock_audit_products = response.stock_audit_products;
          this.$loading.finish();
        })
        .catch((error) => {
          this.$loading.finish();
        });
    },

    openStoreStockAuditProductForm() {
      if (this.$acl.can("stockAuditAddProduct")) {
        this.$refs.StoreStockAuditProductForm.open(this.stockAudit);
      }
    },

    handleUpdateStockFromStockAudit() {
      this.updateStockFromStockAudit({
        stockAuditId: this.stockAudit.id,
      });
    },

    handleUpdateStockFromStockAuditProduct(stockAuditProductId) {
      this.updateStockFromStockAudit({
        stockAuditProductId: stockAuditProductId,
      });
    },

    updateStockFromStockAudit(params) {
      this.$http
        .store("stock/update-stock-from-stock-audit", params)
        .then((response) => {
          this.selectStockAuditProduct(this.searchParams.page);
        });
    },

    undoUpdateStock(stockAuditProduct) {
      var params = {
        product_id: stockAuditProduct.product_id,
        product_variant_id: stockAuditProduct.product_variant_id,
        product_company_id: stockAuditProduct.product_company_id,
        stock_audit_id: stockAuditProduct.stock_audit_id,
        company_id: this.stockAudit.company_id,
        id: stockAuditProduct.id,
      };

      this.$http
        .update("stock/update-stock-from-stock-audit", params.id, params)
        .then((response) => {
          this.selectStockAuditProduct(this.searchParams.page);
        });
    },

    deleteProduct(id) {
      this.$loading.start();
      this.$http
        .destroy("stock/stock-audit-product", id)
        .then((response) => {
          this.selectStockAuditProduct();
        })
        .catch((error) => {
          this.selectStockAuditProduct();
        });
    },
    handleSearchInput(text) {
      this.searchParams.text = text;
      this.selectStockAuditProduct();
    },
    pagination(page) {
      this.searchParams.page = page;
      this.selectStockAuditProduct();
    },
    handleStartCounting() {
      this.$refs.StartStockAuditByBrandForm.open(this.stockAudit);
    },
    handleCancelCounting() {
      this.$refs.CancelStockAuditByBrandForm.open(this.stockAudit.id);
    },
  },
};
</script>

<style></style>

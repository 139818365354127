<template>
  <v-dialog v-model="dialog" persistent width="500px">
    <v-card>
      <v-card-title> Selecionar Marca </v-card-title>

      <v-card-text>
        <v-row>
          <v-col>
            <BrandsSearch label="" v-model="form.brand" ref="BrandsSearch" />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-btn text @click="dialog = false"> Cancelar </v-btn>
        <v-btn color="primary" @click="confirm()"> Confirmar </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import BrandsSearch from "@/components/brands/ui/BrandsSearch.vue";
export default {
  components: { BrandsSearch },

  data() {
    return {
      dialog: false,

      baseForm: {
        brand: null,
        stock_audit_id: null,
      },
      form: {},
    };
  },

  methods: {
    async open(stock_audit_id) {
      this.reset();
      this.form.stock_audit_id = stock_audit_id;
      this.dialog = true;
    },

    reset() {
      this.form = JSON.parse(JSON.stringify(this.baseForm));
    },

    async confirm() {
      this.$loading.start();
      await this.$http
        .update("stock/cancel-stock-audit", this.form.stock_audit_id, {
          brand_id: this.form.brand.id,
        })
        .then(async () => {
          this.$emit("store");
          this.$loading.finish();
          this.dialog = false;
        })
        .catch((error) => {
          this.$loading.finish();
        });
    },
  },
};
</script>

<style></style>
